<template>
  <div class="DataAnalysisInfo">
    <div class="dataAnalysisItem" @click="$router.go(-1)">
      <div class="tltle">{{ index }}. {{ dataAnalysisItem.title }}</div>
      <div class="rigth">
        <div class="price">{{ dataAnalysisItem.price }} 元/万字</div>

        <div class="kind">
          <i class="icon"></i>
          <span class="txt">分析报告样本</span>
        </div>
      </div>
    </div>

    <el-collapse v-model="activeNames">
      <el-collapse-item class="title" title="一、确定数据源" name="1">
        <!-- 词频及关联数据分析 -->
        <div class="data_source">
          <div class="associated">
            <span>*</span>
            <span>词频及关联数据分析</span>
            <span>···</span>
          </div>
          <!-- 通用中文语境 -->
          <div class="general">
            <img :src="require('assets/images/cancelAtten.png')" alt="" />
            通用中文语境
          </div>
          <!-- 上传分析文档（doc、docx、wps -->
          <div class="upload" @click="uploadDialog = true">
            <img :src="require('assets/images/add.png')" alt="" />
            <span>上传分析文档（doc、docx、wps 格式) ···</span>
          </div>

          <!-- 词频及关联分析报告 -->
          <div class="report" v-for="item in 4" :key="item">
            <div class="title">1. 词频及关联分析报告</div>
            <div class="delete" @click="deleteDialog = true">
              <i class="icon"></i>
              删除
            </div>
          </div>

          <!-- 分页 -->
          <div>
            <IdiomPager
              :page-num="pageNum"
              :page-size="pageSize"
              @currentChange="currentChange"
            />
          </div>
        </div>

        <div class=""></div>
      </el-collapse-item>
      <el-collapse-item title="二、确定分析维度" name="2">
        <!-- 二、确定分析维度 -->
        <div class="analysis_dimension">
          <!-- 文本维度 -->
          <div class="Text_dimension">
            <div class="text_analysis">
              <span>*</span>
              <span>文本维度</span>
            </div>

            <div class="full_text">
              <img :src="require('assets/images/cancelAtten.png')" alt="" />
              全文
            </div>

            <div
              class="full_info"
              v-for="(item, index) in Text_dimension"
              :key="index"
            >
              <el-checkbox v-model="item.radio">{{ item.text }}</el-checkbox>
              <div class="add_keywords">
                <div class="leftTemp">
                  <img
                    @click="isAlterKeywords(1, index)"
                    :src="require('assets/images/add.png')"
                    alt=""
                  />
                  <span> 新增关键词（{{ item.keywords.length }}/100)： </span>
                </div>
                <div class="ringth">
                  <div
                    class="item"
                    v-for="(item1, index1) in item.keywords"
                    :key="index1"
                  >
                    <span @click="isAlterKeywords(2, index, index1)">
                      {{ item1 }}
                    </span>
                    <img
                      @click="deleteKeywords(index, index1)"
                      :src="
                        require('assets/images/goldenSentence/analysis-delete.png')
                      "
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 时间维度 -->
          <div class="Time_dimension">
            <div class="text_analysis">
              <span>*</span>
              <span>时间维度</span>
            </div>

            <div class="radio">
              <el-radio v-model="radiox" label="1">今年</el-radio>
              <el-radio v-model="radiox" label="2">近一年</el-radio>
              <el-radio v-model="radiox" label="3">近三年</el-radio>
              <el-radio v-model="radiox" label="4"
                >其它年限 2021年-月-日 至 -年-月-日</el-radio
              >
            </div>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item title="三、确定数据分析关键词" name="3">
        <!-- 三、确定数据分析关键词 -->
        <div class="analysis_dimension">
          <div class="full_info">
            <el-checkbox v-model="radio">通用中文语境关键词典</el-checkbox>
            <div class="add_keywords">
              <div class="leftTemp">
                <img
                  @click="isAlterKeywords(3)"
                  :src="require('assets/images/add.png')"
                  alt=""
                />
                <span> 新增关键词（{{ Keywordslist.length }}/100)： </span>
              </div>
              <div class="ringth">
                <div
                  class="item"
                  v-for="(item, index) in Keywordslist"
                  :key="index"
                >
                  <span @click="isAlterKeywords(3, index, '', 0)">
                    {{ item }}
                  </span>
                  <img
                    @click="deleteKeywords('', index, 'position')"
                    :src="
                      require('assets/images/goldenSentence/analysis-delete.png')
                    "
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item title="四、支付" name="4">
        <!-- 四、支付 -->
        <div class="pay">
          <div class="data">
            <div class="data_sum_text">数据总量:</div>
            <div class="sum">0.28万字符</div>
          </div>
          <div class="data">
            <div class="data_sum_text">费用:</div>
            <div class="sum">
              <span> 2.8 </span>
              元
            </div>
          </div>

          <div class="text">点击生成数据&费用</div>

          <div class="btn">
            <el-button @click="addShopDialog = true" type="danger"
              >支付</el-button
            >
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>

    <!-- 上传单个文档弹窗 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="uploadDialog"
      class="paymentSuccess"
    >
      <div class="content">仅支持doc/docx/wps格式文档</div>
      <div class="footer">
        <div class="footer-btn" @click="uploadDialog = false">上传文件夹</div>
        <div class="footer-btn" @click="uploadDialog = false">上传单个文档</div>
      </div>
    </el-dialog>

    <!-- 文档删除 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="deleteDialog"
      class="paymentSuccess"
    >
      <div class="content">确定要删除此文档/文件夹吗？</div>
      <div class="footer">
        <div class="footer-btn" @click="deleteDialog = false">确定</div>
        <div class="footer-btn" @click="deleteDialog = false">删除</div>
      </div>
    </el-dialog>

    <!-- 关键词删除 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="keywordsDialog"
      class="paymentSuccess"
    >
      <div class="content">确定要删除关键词吗</div>
      <div class="footer">
        <div class="footer-btn" @click="keywordsDialog = false">确定</div>
        <div class="footer-btn" @click="keywordsDialog = false">删除</div>
      </div>
    </el-dialog>

    <!-- 立即购买弹窗 -->
    <el-dialog
      width="500px"
      :close-on-click-modal="false"
      title="确认订单"
      :visible.sync="addShopDialog"
    >
      <div class="shop_box">
        <div class="shop_left">
          <img
            class="img"
            :src="require('assets/images/no-shop-img100.png')"
            alt=""
          />
        </div>
        <div class="shop_right">
          <div class="txet_top">购买 商品名称</div>
          <div class="text_bottom">
            <div class="text_left">单价: ¥999</div>
            <div class="text_center">数量: 11</div>
            <div class="text_right">¥ 999</div>
          </div>
        </div>
      </div>

      <div class="coupons_box">
        <div class="coupons_top">
          <i class="icon"></i>
          当前优惠
        </div>
        <div class="coupons_bottom">
          <div class="text_left">无优惠</div>
          <div class="text_right">- ¥ 0.00</div>
        </div>
      </div>
      <div class="payment_text">
        应付款:
        <span class="text">¥ 999</span>
      </div>
      <div slot="footer" class="dialog-footer">
        <div class="payment_text">
          应付款:
          <span class="text">¥ 999 </span>
        </div>
        <el-button type="primary" @click="submitOrder">提交订单</el-button>
      </div>
    </el-dialog>

    <!-- 扫码支付 -->
    <el-dialog
      title="扫码支付"
      :close-on-click-modal="false"
      :visible.sync="paymentPopup"
      @close="handleClosePaymentPopup"
      class="payment"
    >
      <div class="content">
        <div class="notes">请使用微信扫码下方二维码完成支付</div>
        <div class="imgBox">
          <img :src="qrCodeurl.codeImg" alt="" />
        </div>
        <!-- <el-button type="primary" @click="submitOrder">提交订单</el-button> -->
        <div class="price">
          应付款：<span>￥<span>999</span></span>
        </div>
      </div>
    </el-dialog>

    <!-- 支付成功 -->
    <el-dialog
      title="提示"
      :close-on-click-modal="false"
      :visible.sync="paymentSuccessPopup"
      class="paymentSuccess"
    >
      <div class="content">支付成功,是否前去订单中心</div>
      <div class="footer">
        <div
          class="footer-btn"
          @click="
            paymentSuccessPopup = false;
            paymentPopup = false;
            addshopDialog = false;
            $router.push({
              name: 'OrderCenter',
            });
          "
        >
          确定
        </div>
        <div
          class="footer-btn"
          @click="
            paymentSuccessPopup = false;
            paymentPopup = false;
            addshopDialog = false;
          "
        >
          取消
        </div>
      </div>
    </el-dialog>

    <!--  修改关键词 添加关键词 -->
    <el-dialog
      :title="i == 1 ? '新增关键词' : '修改关键词'"
      :close-on-click-modal="false"
      :visible.sync="Keywords"
      class="Keywords"
      @close="closeKeywords"
    >
      <div class="textarea_box">
        <el-input
          type="textarea"
          :rows="4"
          placeholder="请输入关键词，不超过30个字符"
          maxlength="30"
          v-model="textarea"
        >
        </el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addKeywords">确 定</el-button>
        <el-button @click="Keywords = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import IdiomPager from "components/idiom/pager/IdiomPager";
export default {
  name: "DataAnalysisInfo",
  components: {
    IdiomPager,
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 20,
      index: this.$route.query.index,
      dataAnalysisItem: JSON.parse(this.$route.query.item),
      // 文本维度
      Text_dimension: [
        {
          text: "主标题含有特定关键词的所有全文",
          id: 1,
          keywords: ["产业经济", "测试"],
          radio: false,
        },
        {
          text: "段落含有特定关键词的所有自然段",
          id: 2,
          keywords: ["产业经济", "测试"],
          radio: false,
        },
        {
          text: "句子含有特定关键词的所有句子",
          id: 3,
          keywords: ["产业经济", "测试"],
          radio: false,
        },
      ],
      // 文本维度索引
      Text_dimensionIndex: "",
      // 关键字索引
      keywordsIndex: "",
      // 确定数据分析关键词
      radio: false,
      // 时间维度
      radiox: "4",
      // 需要展开的数组
      activeNames: ["1", "2", "3", "4"],
      // 上传弹窗
      uploadDialog: false,
      // 文档删除弹窗
      deleteDialog: false,
      // 关键词删除弹窗
      keywordsDialog: false,
      // 2 修改 1 添加
      i: "",
      // 修改关键词弹窗 添加关键词弹窗
      Keywords: false,
      // 关键字
      textarea: "",
      // 三、确定数据分析关键词数组
      is: "",
      Keywordslist: ["aasd", "测试"],
      // 支付成功弹窗
      paymentSuccessPopup: false,
      // 扫码弹窗
      paymentPopup: false,
      // 确认订单弹窗
      addShopDialog: false,
      // 二维码URL
      qrCodeurl: {},
      //定时器
      timer: null,
    };
  },
  methods: {
    //当前页改变
    currentChange(cur) {
      this.pageNum = cur;
    },
    // 购买商品
    submitOrder() {
      this.paymentPopup = true;
    },

    // 关闭支付弹窗停止定时器
    handleClosePaymentPopup() {
      // console.log(1);
      window.clearInterval(this.timer);
    },

    // 打开 2修改关键词 1添加关键词 弹窗
    isAlterKeywords(i, index, index1, is) {
      this.i = i;
      this.is = is;
      this.Text_dimensionIndex = index;
      this.keywordsIndex = index1;
      this.Keywords = true;
      if (i == 2) {
        this.textarea = this.Text_dimension[index].keywords[index1];
      } else if (i == 3) {
        this.textarea = this.Keywordslist[index];
      }
    },

    // 关闭 修改关键词 添加关键词 弹窗
    closeKeywords() {
      this.textarea = "";
    },

    // 2修改关键词 1添加关键词
    addKeywords() {
      if (this.textarea) {
        if (this.i == 1) {
          this.Text_dimension[this.Text_dimensionIndex].keywords.push(
            this.textarea
          );
        } else if (this.i == 2) {
          this.Text_dimension[this.Text_dimensionIndex].keywords[
            this.keywordsIndex
          ] = this.textarea;
        } else {
          if (this.is == 0) {
            this.Keywordslist[this.Text_dimensionIndex] = this.textarea;
          } else {
            this.Keywordslist.push(this.textarea);
          }
        }
        this.Keywords = false;
      } else {
        this.$message({
          type: "error",
          message: "请输入关键字!",
        });
      }
    },

    // 删除关键字
    deleteKeywords(index, index1, position) {
      if (position) {
        this.Keywordslist.splice(index1, 1);
        return;
      }
      this.Text_dimension[index].keywords.splice(index1, 1);
    },
  },

  created() {},
};
</script>

<style lang="scss" scoped>
::v-deep .el-checkbox__inner {
  border-radius: 8px;
  width: 16px;
  height: 16px;

  &::after {
    left: 5px;
    top: 2px;
  }
}
.DataAnalysisInfo {
  padding: 0 20px;

  .dataAnalysisItem {
    cursor: pointer;
    padding: 20px 0;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    align-items: center;
    .tltle {
      width: 50%;
      color: #4587ff;
    }

    .rigth {
      flex: 1;
      display: flex;
      justify-content: space-between;

      .kind {
        display: flex;
        align-items: center;
        color: #4587ff;
        .icon {
          width: 16px;
          height: 16px;
          background: url("~assets/images/goldenSentence/analysis-blue.png")
            100% no-repeat;
          margin-right: 5px;
        }
      }
    }
  }
  ::v-deep .el-collapse {
    .el-collapse-item__header {
      font-weight: 800;
      font-size: 14px;
    }
    // 词频及关联数据分析
    .data_source {
      .associated {
        margin: 10px 0;
        span {
          &:nth-child(1) {
            color: red;
          }
          &:nth-child(2) {
            color: #4587ff;
          }
          &:nth-child(3) {
            margin-left: 20px;
            color: #4587ff;
            cursor: pointer;
          }
        }
      }
      // 通用中文语境
      .general {
        display: flex;
        align-items: center;
        img {
          margin-right: 15px;
          width: 16px;
          height: 16px;
        }
      }

      // 上传分析文档（doc、docx、wps
      .upload {
        cursor: pointer;
        margin-top: 20px;
        display: flex;
        align-items: center;
        img {
          margin-right: 12px;
          width: 16px;
          height: 16px;
        }

        span {
          color: #4587ff;
        }
      }

      // 词频及关联分析报告
      .report {
        margin: 10px;
        display: flex;
        justify-content: space-between;

        .delete {
          cursor: pointer;
          display: flex;
          align-items: center;
          .icon {
            width: 16px;
            height: 16px;
            background: url("~assets/images/goldenSentence/analysis-delete.png")
              100% no-repeat;
            margin-right: 12px;
          }

          &:hover {
            .icon {
              background: url("~assets/images/goldenSentence/analysis-delete-blue.png")
                100% no-repeat;
            }

            color: #4587ff;
          }
        }
      }
    }

    // 确定分析维度
    .analysis_dimension {
      // 文本维度
      .Text_dimension {
        .text_analysis {
          span {
            &:nth-child(1) {
              color: red;
            }
            &:nth-child(2) {
              font-weight: 800;
              color: #000;
            }
          }
        }

        .full_text {
          margin: 10px 0;
          display: flex;
          align-items: center;
          img {
            margin-right: 12px;
            width: 16px;
            height: 16px;
          }
        }

        .full_info {
          margin: 10px 0;
          .add_keywords {
            margin: 5px 0;
            display: flex;
            .leftTemp {
              display: flex;
              align-items: center;
              img {
                cursor: pointer;
                margin-right: 12px;
              }
            }

            .ringth {
              display: flex;
              align-items: center;

              .item {
                display: flex;
                flex-wrap: wrap;
                margin-right: 12px;
                align-items: center;

                span {
                  cursor: pointer;
                  color: #4587ff;
                  text-decoration: underline #4587ff;
                }

                img {
                  cursor: pointer;
                  margin-left: 5px;
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }
        }
      }

      // 时间维度
      .Time_dimension {
        .text_analysis {
          span {
            &:nth-child(1) {
              color: red;
            }
            &:nth-child(2) {
              font-weight: 800;
              color: #000;
            }
          }
        }

        .radio {
          display: flex;
          flex-direction: column;
          .el-radio {
            margin: 10px 0;
          }
        }
      }
    }

    // 确定数据分析关键词
    .analysis_dimension {
      .full_info {
        margin: 10px 0;
        .add_keywords {
          margin: 5px 0;
          display: flex;
          .leftTemp {
            display: flex;
            align-items: center;
            img {
              cursor: pointer;
              margin-right: 12px;
            }
          }

          .ringth {
            display: flex;
            align-items: center;

            .item {
              display: flex;
              flex-wrap: wrap;
              margin-right: 12px;
              align-items: center;

              span {
                cursor: pointer;
                color: #4587ff;
                text-decoration: underline #4587ff;
              }

              img {
                cursor: pointer;
                margin-left: 5px;
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
    }

    // 支付
    .pay {
      .data {
        display: flex;
        align-items: center;
        margin: 10px 0;

        .data_sum_text {
          width: 100px;
        }

        .sum {
          span {
            color: red;
          }
        }
      }

      .text {
        color: #4587ff;
      }

      .btn {
        margin-top: 40px;
        .el-button {
          width: 160px;
          font-size: 16px;
          height: 48px;
        }
      }
    }
  }
}

// 上传弹窗
.paymentSuccess {
  ::v-deep .el-dialog {
    margin-top: 20vh !important;
    width: 340px;
    height: 200px;
    background: #ffffff;
    box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
    .el-dialog__header {
      padding: 12px 12px 0px;
      .el-dialog__title {
        font-size: $font-size-small;
        font-family: PingFang SC;
        font-weight: 400;
        color: $color-text-gray;
      }
      .el-dialog__headerbtn {
        top: 12px;
        right: 12px;
      }
    }
    .el-dialog__body {
      padding: 35px 39px;
      .content {
        margin: 40px 0;
        text-align: center;
        font-size: $font-size-medium-x;
        font-family: PingFang SC;
        font-weight: 500;
        color: $color-text-black;
        line-height: 26px;
        span {
          color: $color-bg-heightlight;
        }
      }
      .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 25px;
        .footer-btn {
          font-size: 18px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: $color-bg-heightlight;
          cursor: pointer;
        }
      }
    }
  }
}

// 上传弹窗
.Keywords {
  ::v-deep .el-dialog {
    margin-top: 20vh !important;
    width: 428px;

    background: #ffffff;
    box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
    .el-dialog__header {
      padding: 12px 12px 0px;
      .el-dialog__title {
        font-size: $font-size-small;
        font-family: PingFang SC;
        font-weight: 400;
        color: $color-text-gray;
      }
      .el-dialog__headerbtn {
        top: 12px;
        right: 12px;
      }
    }
    .el-dialog__body {
      padding: 35px 39px;
    }
  }
}

// 立即购买弹窗
.shop_box {
  padding: 0 0 20px 0;
  border-bottom: 1px solid #cccc;
  display: flex;
  // justify-content: center;
  align-items: center;
  .shop_left {
    margin: 0 10px;
    .img {
      width: 60px;
      height: 60px;
    }
  }

  .shop_right {
    display: flex;
    flex: 1;
    flex-direction: column;
    .txet_top {
      color: #000;
      font-size: 16px;
      margin: 0 0 15px 0;
    }

    .text_bottom {
      display: flex;
      justify-content: space-between;
      padding: 0 20px 0 0;
      font-size: 14px;
      .text_left {
        color: #999999;
      }

      .text_center {
        color: #999999;
      }

      .text_right {
        color: #eb441e;
      }
    }
  }
}
.coupons_box {
  padding: 20px 0;
  border-bottom: 1px solid #cccc;
  .coupons_top {
    display: flex;
    align-items: center;
    color: #000;
    font-size: 16px;
    .icon {
      width: 14px;
      height: 10px;
      @include backgroundGroup("../../assets/images/coupons.png");
      margin: 0 10px 0 5px;
    }
  }

  .coupons_bottom {
    margin-top: 15px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px 0 30px;
    .text_left {
      color: #999999;
    }

    .text_right {
      color: #eb441e;
    }
  }
}

.payment_text {
  padding: 20px 0 70px 0;
  width: 100%;
  text-align: right;
  font-size: 14px;
  color: #000;
  .text {
    color: #eb441e;
    margin-right: 15px;
  }
}

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: right;
  .payment_text {
    padding: 0;
    font-weight: 800;
    font-size: 16px;
  }
}

// 支付弹窗
.payment {
  ::v-deep .el-dialog {
    margin-top: 20vh !important;
    width: 500px;
    height: 390px;
    background: #ffffff;
    border: 0px solid #818790;
    box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
    .el-dialog__header {
      height: 42px;
      background: #f4f6f7;
      padding: 0;
      line-height: 42px;
      .el-dialog__title {
        margin-left: 15px;
        font-size: $font-size-medium;
        font-family: PingFang SC;
        font-weight: 500;
        color: #393939;
      }
      .el-dialog__headerbtn {
        top: $font-size-small;
        right: $font-size-small;
        font-size: $font-size-large;
      }
    }
    .el-dialog__body {
      padding: 65px 20px;
      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .notes {
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: $color-text-black;
        }
        .imgBox {
          width: 110px;
          height: 110px;
          margin-top: 30px;
          img {
            width: 110px;
            height: 110px;
          }
        }
        .price {
          margin-top: 30px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
          span {
            color: #eb441e;
            span {
              font-size: $font-size-large;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>
