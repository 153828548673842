<template>
  <!-- <div class="idiom-pagination" v-if="!(size == 1 && hideOnSinglePage)"> -->
  <div class="idiom-pagination">
    <span class="turn-left" @click="prev">
      <span v-show="pageNum == 1" class="gray">
        <img :src="require('assets/images/idiom/arrow-left.png')" alt="" />
      </span>
      <span v-show="pageNum > 1" class="blue">
        <img :src="require('assets/images/idiom/arrow-blue-left.png')" alt="" />
      </span>
    </span>
    <div class="center">
      <span class="cur">{{ pageNum }}</span
      >&nbsp;/
      <span class="size">{{ size }}</span>
    </div>
    <span class="turn-right" @click="next">
      <span v-show="pageNum == size" class="gray">
        <img :src="require('assets/images/idiom/arrow-right.png')" alt="" />
      </span>
      <span v-show="pageNum < size" class="blue">
        <img
          :src="require('assets/images/idiom/arrow-blue-right.png')"
          alt=""
        />
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: "IdiomPager",
  props: {
    pageNum: {
      type: Number,
      default: 1,
    },
    pageSize: {
      default: 1,
    },
    hideOnSinglePage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cur: this.pageNum,
      size: this.pageSize,
    };
  },
  watch: {
    pageNum() {
      this.cur = this.pageNum;
    },
    pageSize(size) {
      if (size <= 0) {
        this.size = 1;
      } else {
        this.size = this.pageSize;
      }
    },
  },
  methods: {
    prev() {
      if (this.cur - 1 > 0) {
        this.cur--;
        this.$emit("currentChange", this.cur);
      }
    },
    next() {
      if (this.cur + 1 <= this.size) {
        this.cur++;
        this.$emit("currentChange", this.cur);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.idiom-pagination {
  @include flex-between(center);
  @include noselect;
  height: 44px;
  > span {
    cursor: pointer;
    > span {
      @include flex-between(center);
      width: 24px;
      height: 24px;
    }
    .gray {
      img {
        width: 6.9px;
        height: 12px;
      }
    }
    .blue {
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  .center {
    //width:43px;
    margin: 0 11px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
  }
}
</style>
